import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

function User() {
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [listing, setListing] = useState([]);
  const navigate =useNavigate()
  fetch(`https://web.rentah.com/api/users/${userId}`, {})
    .then((res) => {
      return res.json();
    })
    .then((response) => {
      if (response.status === true) {
        setUser(response.user);
        setListing(response.listings);
      }
    });

    const appScheme = 'https://web.rentah.com';
    // const appStoreURL = 'https://apps.apple.com/in/app/rentah/id1668164022'; // iOS App Store URL
    // const playStoreURL = 'https://play.google.com/store/apps/details?id=com.app.rentah&pcampaignid=web_share'; // Android Play Store URL
    
    // const appScheme = 'yourapp://'; // Custom scheme for your app
  const appStoreURL = 'https://apps.apple.com/in/app/rentah/id1668164022'; // iOS App Store URL
  const playStoreURL = 'https://play.google.com/store/apps/details?id=com.app.rentah&pcampaignid=web_share'; // Android Play Store URL
  

    function getOS() {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
      const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
      const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
      
      if (macosPlatforms.includes(platform)) {
        return 'Mac OS';
      } else if (iosPlatforms.includes(platform)) {
        return 'iOS';
      } else if (windowsPlatforms.includes(platform)) {
        return 'Windows';
      } else if (/Android/.test(userAgent)) {
        return 'Android';
      } else if (/Linux/.test(platform)) {
        return 'Linux';
      }
      return null;
    }
function handleClick() {
  const os = getOS();
  let url =userId
  if (os === 'iOS') {
    // Attempt to open the app using the custom scheme
    const timeout = setTimeout(() => {
      window.location.href = appStoreURL; // Redirect to App Store if the app is not installed
    }, 1000); // Delay of 1000 milliseconds

    // window.location.href = `${appScheme}?id=${encodeURIComponent(url)}&isUser=true`; // Attempt to open the app
    // window.addEventListener('blur', () => clearTimeout(timeout)); // Clear timeout if the app opens
  } else if (os === 'Android') {
    // Attempt to open the app using the custom scheme
    const timeout = setTimeout(() => {
      window.location.href = playStoreURL; // Redirect to Play Store if the app is not installed
    }, 1000); // Delay of 1000 milliseconds

    // window.location.href = `${appScheme}?id=${encodeURIComponent(url)}&isUser=true`; // Attempt to open the app
    // window.addEventListener('blur', () => clearTimeout(timeout)); // Clear timeout if the app opens
  } else {
    alert('Please open this on a mobile device.');  
  }
}
  return (
    <>
      <div className="">
        <div className="" style={{ height: 200, background: "#FFF298" }}></div>
        <div className="px-lg-5 px-md-3 px-2 ">
          <div class="image-container">
            <img
              src={user?.profilePicture}
              alt="Profile Image"
              class="profile-image"
            />
          </div>
          <div
            className="mt-5 d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <div class="fw-bold">{user?.fullName}</div>
              <div className="">member since 2023</div>
              {user?.website && 
              <div>
             
                <i class="bi bi-link-45deg me-2" style={{textDecoration:"underline"}}></i>
                {user?.website}
              </div>
              }
              {user?.phone &&
              <div>
                <i class="bi bi-telephone-fill me-2"></i>
                {user?.phone}
              </div>
}
            </div>
            <div>
              <i class="bi bi-geo-alt"></i>
              {user?.city} , {user?.state}
            </div>
          </div>
          <hr />
          <div>
            <p>{user?.description}</p>
          </div>
          <button
                  className="btn mb-3 form-control mt-2 pt-2 pb-2 "
                  style={{
                    background: "#179778",
                    borderRadius: "10px",
                    color: "white",
                    fontSize:"20px"
                    // margin:"10px"
                  }}
                  data-bs-toggle=""
                  data-bs-target=""
                  onClick={handleClick}
                >
                  I'm Interested
                </button>
          <hr />
          <div style={{ borderBottom: "3px solid green" }}>
            <h6 className="text-center">Listings</h6>
          </div>
          <div
            className="d-flex"
            style={{ flexWrap: "wrap", justifyContent: "space-between" }}
          >
            {listing?.map((i) => (
          
               <div className="mt-4 text-dark" style={{ borderRadius: "10px",textDecoration:"none",cursor:"pointer" }} onClick={()=>{
                 navigate(`/${i._id}`)
               }}>
                <div>
                  <img
                    style={{
                      width: "160px",
                      height: "auto",
                      borderRadius: "10px",
                    }}
                    src={i?.listingPhotos[0]}
                  />
                </div>
                <div className="mt-1 " style={{textDecoration:"none"}}>
                  <div className="fw-bold">${i?.budget}/{i?.listingType? "Rent" : "Sell"}</div>
                  <div className="">{i.title.slice(0, 20)}</div>
                  <div>
                    {" "}
                    <i class="bi bi-geo-alt"></i> {i?.location}
                  </div>
                  {/* <div>
                    <i class="bi bi-person"></i>
                  </div> */}
                </div>
              </div>
           
             
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default User;
